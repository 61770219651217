<template>
    <div>
        <div class="d-flex align-items-center mb-2">
            <h5 class="text-white fw-bold mb-0">{{ $t("help_center") }}</h5>

            <small class="text-white  ms-2">{{ $t('how_can_we_help') }}</small>
        </div>
        <div class="bg-pri p-3 rounded">
            <div v-for="(help, index) in helps" :key="index" class="accordion accordion-flush" id="helps">
                <div class="accordion-item">
                    <h2 class="accordion-header">
                        <button class="accordion-button bg-transparent text-pri px-0" type="button"
                            data-bs-toggle="collapse" :data-bs-target="'#help' + index" aria-expanded="true"
                            aria-controls="collapseOne">
                            <img v-if="help.image" style="width: 40px;" :src="require('@/assets/' + help.image)"
                                class="me-2">
                            {{ help.title }}
                        </button>
                    </h2>
                    <div :id="'help' + index" class="accordion-collapse text-pri collapse" data-bs-parent="helps">
                        <div class="accordion-body">
                            <template v-if="index == 5">
                                <uni-view data-v-70f2bf7a="">
                                    <p style="text-align:justify;" size="0" _root="undefined" __ownerid="undefined"
                                        __hash="undefined" __altered="false"><span style="color:#cccccc">The daily income of
                                            staking mining is calculated on USDT the users have.</span></p>
                                    <p class="mb-0"><span style="color:#cccccc">Earning calculations :</span></p>
                                    <p class="mb-0"><span style="color:#cccccc">1001 - 5000 USDT token value about 3.6% -
                                            4.5% daily
                                            return.</span></p>
                                    <p class="mb-0"><span style="color:#cccccc">5001 - 20000 USDT token value about 4.5% -
                                            6.2% daily
                                            return.</span></p>
                                    <p class="mb-0"><span style="color:#cccccc">20001 - 50000 USDT token value about 6.2% -
                                            7.5% daily
                                            return.</span></p>
                                    <p class="mb-0"><span style="color:#cccccc">50001 - 100000 USDT token value about 7.5% -
                                            9.1% daily
                                            return. </span></p>
                                    <p class="mb-0"><span style="color:#cccccc">100001 - <strong>≥</strong>500000 USDT token
                                            value about
                                            9.1% - 15% daily return.The mining pool pays the income every 24 hours. Of
                                            course , the more cryptocurrencies you join in a mining , the higher the
                                            income.</span></p>
                                </uni-view>

                            </template>
                            <template v-if="index == 10">
                                <uni-view data-v-70f2bf7a="">
                                    <p class="mb-0"><span style="font-size:14px">The daily income of staking mining is
                                            calculated on USDT
                                            the users have.</span></p>
                                    <p class="mb-0"><span style="font-size:14px">Earning calculations :</span></p>
                                    <p class="mb-0"><span style="font-size:14px">1001- 5000 USDT token value about 3%-5%
                                            daily
                                            return.</span></p>
                                    <p class="mb-0"><span style="font-size:14px">5001- 20000 USDT token value about
                                            3.5%-6.5% daily
                                            return.</span></p>
                                    <p class="mb-0"><span style="font-size:14px">20001- 50000 USDT token value about 4%-7%
                                            daily
                                            return.</span></p>
                                    <p class="mb-0"><span style="font-size:14px">50001- 100000 USDT token value about
                                            5%-7.5% daily
                                            return. </span></p>
                                    <p class="mb-0"><span style="font-size:14px">100001- &gt;500000 USDT token value about
                                            6%-8% daily
                                            return.The mining pool pays the income every 24 hours. Of course , the more
                                            cryptocurrencies you join in a mining , the higher the income.</span></p>
                                </uni-view>

                            </template>
                            <template v-else>
                                {{ help.content }}

                            </template>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    data() {
        return {
            helps: [
                {
                    image: '1.png',
                    title: "How to withdraw minig revenue?",
                    content: "The intelligent contract of cloud node on the chain supports USDT revenue transfer, with a minimum withdraw of 50USDT. Your daily income is converted into USDT tokens through EUSM, and then withdrawals can be initiated.The intelligent contract will automatically send USDT to the wallet address of your verified valid cloud node. Please note that other addresses are not supported in the contract."
                },
                {
                    image: '2.png',
                    title: "When do you start calculating income?",
                    content: "Your income will be calculated on the day you participate in mining. The mining machine's revenue based on the computing power provided per minute.If you quit mining on the day or the system checks the token value at anytime, the token value on the chain is lower than or nor in the mining pool, then all your mining earning on that day will be distributed to other mining pool users."
                }, {
                    image: '3.png',
                    title: "What is node mining?",
                    content: "USDT stored in their own wallet, '0' risk, the new blockchain data management and computing model, to boost DEFI ecology. Via the total value of each user node production ,liquidity node mining without any reservation and pre-mining and incremental behavior, all ETH/TRX users to provide on-chain liquidity, all this will be automatically locked through the smart contract node execution revenue. Currently, you can participate through any wallet, to Miners new generation node mining to take, upon the end of the total revenue obtained through liquidity node mining, through the USDT tokens divide each user's DEFI wallet"
                }, {
                    image: '4.png',
                    title: "Where are the mining earnings from?",
                    content: "Once the user participates in mining, the liquidity node mining will be activated , the mining machine will continue to operate ,and the computing power report will be generated every minute. Mining revenue is calculated as a percentage of the wallet tokens of the users of the liquidity node pool. After the community submission is successful , the mining revenue will be distributed to the user's centralized wallet. The proportion of the total amount of mobile node mining to the total amount of tokens in the user's wallet. The more tokens you have in the liquidity pool,the more tokens you will get from mining.",
                }, {
                    title: "Service fees for ERC 20 network",
                    content: "Withdrawal with ERC 20 network, the service fees will be charged between 25-50 USDT"
                },
                {
                    title: "Mining machine reward description",
                    content: "Total revenue: Calculated based on the core USDT token value of the user's wallet."
                }, {
                    title: "How to enter staking mining ?",
                    content: "The staking mining time starts randomly , and the 15-30 days staking mining reward is experienced by selecting lucky users."
                }, {
                    title: "Staking reward activities",
                    content: "Welcome to ECOS ETHERCLOUD Book an event: When cumulative deposit of  Staking funds is 5,000 USDT you can get 0.25 ETH reward, the Staking funds reach 10,000 USDT , you can get 0.5 ETH reward , the Staking funds reach 20,000 USDT, You can get 1.0 ETH reward and the Staking funds reach 50,000 USDT, You can get 3.0 ETH reward. Valid until the trial period ends!"
                }, {
                    title: "The advantages of staking mining.",
                    content: "Staking mining is the staking or lending cryptocurrency assets to generate high returns, which are distributed in the form of additional cryptocurrency. This is an entirely new approach , and this application of decentralized finance has grown in popularity recently. Staking mining is currently the biggest growth driver for the industry."
                },
                {
                    title: "Is staking mining risky?",
                    content: "Staking is to the way cryptocurrencies are locked up on a platform or protocol to earn staking rewards and other privileges. Cryptocurrency holders earn passive income while enjoying currency ownership. Very safe and zero risk for you."
                }, {
                    title: "How is the Staking mining income calculated?",
                    content: "The daily income of staking mining is calculated  on USDT the users have."
                }
            ]
        }
    }
}
</script>
<style>
.accordion,
.accordion-item {
    background-color: transparent !important;
}

button:focus {
    outline: none !important;
}

.accordion-button:not(.collapsed) {
    box-shadow: inset 0 3px 0 #5e72a6 !important;
}</style>