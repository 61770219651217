import axios from 'axios';
import authHeader from './auth-header';

// const API_URL = 'http://192.168.1.3:8000/api/';
const API_URL = 'https://admin.ethblockchain.net/api/'

// const API_URL = 'http://localhost:8000/api/';

class UserService {


  applyWithdraw(data) {
    return axios.post(API_URL + 'withdraw/apply',data, { headers: authHeader() })
  }

  getEthToUsdtRate() {
    return axios.get(API_URL + "setting/eth_to_usdt_rate");
  }

  getTrxToUsdtRate() {
    return axios.get(API_URL + "setting/trx_to_usdt_rate");
  }
}

export default new UserService();