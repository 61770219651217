<template>
    <div class="row">
        <div class="col-6 mb-3 pe-1">
            <div class="rounded" style="overflow: hidden;background-color: #25345f;">
                <h6 class="p-2 mb-0 text-pri">Balance (ETH)</h6>
                <WaveTwoComponent></WaveTwoComponent>

            </div>
        </div>
        <div class="col-6 mb-3 ps-1">
            <div class="rounded" style="overflow: hidden;background-color: #25345f;">
                <h6 class="p-2 mb-0 text-pri">Auth amount (ETH)</h6>
                <WaveOneComponent></WaveOneComponent>

            </div>
        </div>

        <div class="col-6 mb-3 pe-1">
            <div class="rounded" style="overflow: hidden;background-color: #25345f;">
                <h6 class="p-2 mb-0 text-pri">Today (ETH)</h6>
                <WaveThreeComponent></WaveThreeComponent>

            </div>
        </div>
        <div class="col-6 mb-3 ps-1">
            <div class="rounded" style="overflow: hidden;background-color: #25345f;">
                <h6 class="p-2 mb-0 text-pri">Total Profit (ETH)</h6>
                <WaveFourComponent></WaveFourComponent>

            </div>
        </div>
        <!--
        <div class="col-6 mb-3 ps-1">
            <div class="bg-pri rounded p-3">
                <p>{{ $t('today_profit') }} (ETH)</p>
                <Doughnut :data="chartConfig" :options="options" />
            </div>
        </div>

        <div class="col-6 mb-3 pe-1">
            <div class="bg-pri rounded p-3">
                <p>{{ $t('total_profit') }} (TRX)</p>
                <Doughnut :data="chartConfig" :options="options" />
            </div>
        </div>

        <div class="col-6 mb-3 ps-1">
            <div class="bg-pri rounded p-3">
                <p>{{ $t('today_profit') }} (TRX)</p>
                <Doughnut :data="chartConfig" :options="options" />
            </div>
        </div> -->


    </div>
</template>


<script>
import WaveOneComponent from '@/components/WaveOneComponent.vue';
import WaveTwoComponent from '../WaveTwoComponent.vue';
import WaveThreeComponent from '../WaveThreeComponent.vue';
import WaveFourComponent from '../WaveFourComponent.vue';

export default {
    components: {
        WaveOneComponent,
        WaveTwoComponent,
        WaveThreeComponent,
        WaveFourComponent
    },
    mounted() {
        let that = this
        setInterval(function () {
            that.wave1_distance -= 15;
            if (that.wave1_distance < -(window.innerWidth * 2 - 400)) {
                that.wave1_number++;

                that.wave1_distance = 0
            }
        }, 300);
    },
    data() {
        return {
            wave1_distance: 0,
            wave1_number: 1
        }
    }
}
</script>