<template>
    <iframe src="https://direct.lc.chat/15478314/" width="100%" :height="height" frameborder="0"></iframe>
</template>
<script>
export default {
    computed: {
        height() {
            return window.innerHeight
        }
    },
}
</script>
<style>
iframe {
    /* transform: scale(1.1); */
}
</style>
